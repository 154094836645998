import "bootstrap/dist/css/bootstrap.min.css";
import "@popperjs/core/dist/umd/popper.min.js";
// import "@fontsource/poppins"
import "./src/styles/global.css";


export const onRouteUpdate = ({ location }) => {
    // Check if gtag is defined (it should be available globally after the plugin is loaded)
    // if (typeof window.gtag === 'function') {
    //   // Replace 'your_conversion_event' with the actual event name you want to track
    //   window.gtag('event', 'conversion', {'send_to': 'AW-10990977975/B5ioCPqVhfIYELeH9Pgo'});
    // }
    if (typeof window.gtag === 'function') {
        // Get the pathname from the location object
        const { pathname } = location;
    
        // Check if the current page is the "thank" page
        if (pathname === '/thank') {
          // Replace 'your_conversion_event' with the actual event name you want to track
          window.gtag('event', 'conversion', {'send_to': 'AW-10990977975/B5ioCPqVhfIYELeH9Pgo'});
        }
      }
  };